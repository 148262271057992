import styled from '@emotion/styled';

export const Grid = styled.div`
  display: flex;
  flex-direction: ${props => (props.columns ? 'column' : 'row')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'baseline')};
`;

export const Box = styled.div`
  display: flex;
  flex: 1;
`;
