import { Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import Button from "./button"

const Link = styled(GatsbyLink)`
  color: #7d7d7d;
  font-size: 13px;
  text-decoration: none;
  :hover {
    color: #4680fe;
  }
  transition: color 0.5s;
`

export default Link
export const ExternalLink = Link.withComponent("a")
export const LinkLikeButton = Button.withComponent(GatsbyLink)
export const ExternalLinkLikeButton = Button.withComponent("a")
