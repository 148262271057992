import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import close from "./images/close.svg"
import instagram from "./images/instagram.svg"
import youtube from "./images/youtube.svg"
import { mq } from "../../helpers"

const Container = styled.section`
  ${mq({
    width: "100%",
    maxWidth: "1100px",
    margin: "0 auto",
    padding: ["23px 21px", "35px 46px"],
  })}
`

const Link = styled.a`
  color: #f6f7fb;
  font-family: Bitter;
  font-size: ${props => (props.small ? "18px" : "24px")};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  padding-bottom: ${props => (props.small ? "25px" : "30px")};
  :hover {
    text-decoration: underline;
  }
`

export default function MobileMenu({ isVisible, onClose }) {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        transform: `translate3d(${isVisible ? "0vw" : "-100vw"}, 0, 0)`,
        transition: "transform .5s cubic-bezier(0, .52, 0, 1)",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#4680fe",
        zIndex: 1000001,
      }}
    >
      <Container>
        <Link onClick={() => onClose()}>
          <img
            alt="Botão para fechar janela"
            src={close}
            style={{ width: "28px" }}
          />
        </Link>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "75px 0 45px 0",
          }}
        >
          <Link href="/" onClick={() => onClose()}>
            Produto
          </Link>
          <Link href="/planos" onClick={() => onClose()}>
            Planos
          </Link>
          <Link href="/como-usar" onClick={() => onClose()}>
            Como Usar
          </Link>
          <Link href="https://blog.getlabor.com.br">Blog</Link>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "25px",
            borderTop: "1px solid #2c69ed",
            borderBottom: "1px solid #2c69ed",
          }}
        >
          <Link small href={`${process.env.WEB_APP_URL}/entrar`}>
            Entrar
          </Link>
          <Link small href={`${process.env.WEB_APP_URL}/cadastrar`}>
            Começar Agora
          </Link>
        </div>
        <div
          style={{
            marginTop: "65px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <Link
              rel="noopener noreferrer"
              href="https://www.instagram.com/get_labor"
              target="blank"
            >
              <img
                alt="Logotipo do Instagram"
                src={instagram}
                style={{ marginRight: "20px" }}
              />
            </Link>
            <Link
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCLw9e0ujAxCdSJEDLgTp5rg/videos"
              target="blank"
            >
              <img alt="Logotipo do Youtube" src={youtube} />
            </Link>
          </div>
          <Link
            small
            href="mailto:contato@getlabor.com.br"
            target="blank"
            style={{ marginTop: "25px", paddingBottom: 0 }}
          >
            contato@getlabor.com.br
          </Link>
        </div>
      </Container>
    </div>
  )
}

MobileMenu.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
