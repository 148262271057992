// eslint-disable-next-line
export function subscribe({ email }) {
  return fetch(`${process.env.API_URL}/leads`, {
    method: "POST",
    headers: {
      "Content-type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      lead: { email },
    }),
  })
}
