import { css, Global } from "@emotion/react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import { mq } from "./helpers"

const PageContainer = styled.div`
  ${mq({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    marginTop: ["70px", "82px", "91px"],
    paddingTop: ["55px", "103px", "134px", "164px"],
    overflow: "hidden",
  })}
`

const MainContainer = styled.main`
  ${mq({
    flex: 1,
  })}
`

export default function Page(props) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      style={{
        backgroundColor: "#f6f7fb",
      }}
    >
      <Global
        styles={css`
          * {
            box-sizing: border-box;
          }
        `}
      />
      <PageContainer>
        <Header siteTitle={data.site.siteMetadata.title} />
        <MainContainer>{props.children}</MainContainer>
        <Footer />
      </PageContainer>
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
