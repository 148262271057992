import React from 'react';
import { Field as FormikField } from 'formik';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const InputStyled = styled.input`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  padding: 14px 20px;
  border: solid 1px rgba(149, 149, 149, 0.2);
  background-color: #ffffff;
  outline: none;
  :focus {
    border-color: rgba(70, 128, 254, 0.5);
  }
  ${p =>
    p.hasError &&
    `
    color: #ff6340;
    border-color: currentColor;
  `}
`;
const Wrapper = styled.label`
  display: flex;
  margin: 0 0 20px 0;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  color: #ff6340;
  font-size: 12px;
  font-weight: 500;
`;

function InputHandler(props) {
  const { error, style, inputStyle, ...otherProps } = props;

  return (
    <Wrapper style={style}>
      <InputStyled style={inputStyle} {...otherProps} hasError={!!error} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
}

InputHandler.propTypes = {
  error: PropTypes.string,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
};

const normalize = value =>
  typeof value === 'string' || typeof value === 'number' ? value : '';

export default function Input(props) {
  const { name, ...otherProps } = props;

  return (
    <FormikField name={name}>
      {({ field, form }) => (
        <InputHandler
          {...field}
          {...otherProps}
          value={normalize(props.value || field.value)}
          error={form.touched[name] && form.errors[name]}
        />
      )}
    </FormikField>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
