import React from "react"
import styled from "@emotion/styled"
import Text from "../text"
import NewsletterForm from "./NewsletterForm"
import Link, { ExternalLink } from "../link"
import { Grid } from "../flex-box"
import {
  mq,
  HideBeforeMedium,
  ShowBeforeMedium,
  HideBeforeLarge,
  ShowBeforeExtraLarge,
  HideBeforeExtraLarge,
} from "../helpers"
import laborImg from "./images/labor-logo-no-color.svg"
import chromeExtensionImg from "./images/chrome-web-store.png"

const TopFooterStyled = styled.div`
  ${mq({
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    paddingBottom: "25px",
    borderBottom: "1px solid #e2e5f1",
    alignItems: "center",
  })}
`

const ContactUsWrapper = styled(HideBeforeMedium)`
  ${mq({
    display: ["none", "flex"],
    flexDirection: "column",
    alignItems: "flex-start",
  })}
`

const ChromeExtensionWrapper = styled(HideBeforeMedium)`
  ${mq({
    display: ["none", "flex"],
    flexDirection: "column",
    alignItems: [null, "flex-end", "center"],
  })}
`

const EmailLink = styled(ExternalLink)`
  font-family: Bitter;
  font-size: 16px;
  color: #5f5f5f;
  text-decoration: none;
`

const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 70px;
`

const MenuLink = styled(Link)`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 13px;
  color: #5f5f5f;
`

const ExternalMenuLink = styled(ExternalLink)`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 13px;
  color: #5f5f5f;
`

export default function TopFooter() {
  return (
    <TopFooterStyled>
      <ContactUsWrapper>
        <Text style={{ margin: "0 0 10px 0" }}>
          Estamos constantemente evoluindo o produto.
          <br /> Qualquer dúvida ou sugestão, fale com a gente:
        </Text>
        <EmailLink href="mailto:contato@getlabor.com.br">
          contato@getlabor.com.br
        </EmailLink>
      </ContactUsWrapper>
      <ChromeExtensionWrapper>
        <ShowBeforeExtraLarge>
          <Text style={{ fontSize: "13px", marginBottom: "10px" }}>
            Instale a extensão
          </Text>
        </ShowBeforeExtraLarge>
        <a
          href="https://chrome.google.com/webstore/detail/labor/ljdglolfladhpiemjpmiclhhchjfgehe"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            alt="Link para baixar extensão do chome do Labor"
            src={chromeExtensionImg}
            style={{ width: "110px" }}
          />
        </a>
      </ChromeExtensionWrapper>
      <HideBeforeLarge>
        <NewsletterForm />
      </HideBeforeLarge>
      <ShowBeforeMedium>
        <Grid columns>
          <img alt="Logotipo do Labor" src={laborImg} />
          <Grid
            style={{
              marginTop: "33px",
            }}
          >
            <Grid
              columns
              style={{
                marginRight: "130px",
              }}
            >
              <MenuLink to="/">Produto</MenuLink>
              <MenuLink to="/planos">Planos</MenuLink>
              <MenuLink to="/como-usar" style={{ marginBottom: 0 }}>
                Como Usar
              </MenuLink>
            </Grid>
            <Grid columns>
              <ExternalMenuLink href={`${process.env.WEB_APP_URL}/entrar`}>
                Entrar
              </ExternalMenuLink>
              <ExternalMenuLink href={`${process.env.WEB_APP_URL}/cadastrar`}>
                Cadastrar
              </ExternalMenuLink>
            </Grid>
          </Grid>
        </Grid>
      </ShowBeforeMedium>
    </TopFooterStyled>
  )
}
