import React, { useState } from "react"
import { Formik, Form } from "formik"
import styled from "@emotion/styled"
import * as Yup from "yup"
import Input from "../input"
import Button from "../button"
import Text from "../text"
import { subscribe } from "../../services/api/lead"

const NewsletterWrapper = styled.div`
  flex: 1;
  align-self: center;
  min-width: 342px;
  text-align: left;
`

const NewsletterFormWrapper = styled.div`
  display: flex;
  alignitems: flex-start;
`

const NewsletterSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email inválido")
    .required("Campo obrigatório"),
})

export default function NewsletterForm() {
  const [isSubmitted, setSubmitted] = useState(false)

  return (
    <NewsletterWrapper>
      {isSubmitted ? (
        <Text>Obrigado por se inscrever!</Text>
      ) : (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={NewsletterSchema}
          onSubmit={(values, { setSubmitting }) => {
            const { email } = values
            subscribe({ email }).then(() => {
              setSubmitting(false)
              setSubmitted(true)
            })
          }}
        >
          <Form noValidate>
            <Text style={{ margin: "0 0 10px 0" }}>
              Quer receber conteúdos exclusivos? Inscreva-se abaixo.
            </Text>
            <NewsletterFormWrapper
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              <Input
                type="text"
                name="email"
                value=""
                placeholder="Digite seu e-mail"
                style={{ margin: "0 10px 0 0" }}
                inputStyle={{
                  fontSize: "15px",
                  padding: "8px 15px",
                  minWidth: "244px",
                }}
              />
              <Button
                type="submit"
                style={{
                  fontSize: "15px",
                  padding: "9px 18px",
                  minWidth: "88px",
                }}
              >
                Enviar
              </Button>
            </NewsletterFormWrapper>
          </Form>
        </Formik>
      )}
    </NewsletterWrapper>
  )
}
