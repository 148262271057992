import React from "react"
import styled from "@emotion/styled"
import { ExternalLink } from "../link"
import Text from "../text"
import { mq, ShowBeforeMedium, HideBeforeMedium } from "../helpers"
import laborImg from "./images/labor-logo-no-color.svg"
import youtubeImg from "./images/youtube.svg"
import instagramImg from "./images/instagram.svg"
import facebookImg from "./images/facebook.svg"
import linkedinImg from "./images/linkedin.svg"
import heartImg from "./images/heart.svg"
import chromeExtensionImg from "./images/chrome-web-store.png"

const BottomFooterGrid = styled.div`
  ${mq({
    display: "flex",
    flexDirection: ["column", "row"],
    paddingTop: "25px",
    alignItems: ["flex-start", "center"],
    justifyContent: ["space-between"],
  })}
`

const CallToActionWrapper = styled.div`
  ${mq({
    flex: 1,
    textAlign: "center",
    display: "flex",
    justifyContent: ["space-between", "center"],
    width: "100%",
  })}
`

const SocialLinks = styled.div`
  ${mq({
    flex: 1,
    textAlign: "left",
    display: "flex",
    flexDirection: ["column", "row"],
    justifyContent: ["space-between", "center"],
    width: "100%",
    "div:last-of-type": {
      marginTop: ["15px", 0],
      marginLeft: [0, "15px"],
    },
  })}
`

const LoveTextWrapper = styled.div`
  ${mq({
    flex: 1,
    margin: ["20px 0 0", 0],
    textAlign: "right",
  })}
`

const LoveText = styled(Text)`
  ${mq({
    fontSize: ["13px", "15px"],
    display: "inline",
  })}
`

const LoveLink = styled(ExternalLink)`
  ${mq({
    fontSize: ["13px", "15px"],
  })}
`

export default function BottomFooter() {
  return (
    <BottomFooterGrid>
      <HideBeforeMedium style={{ flex: 1 }}>
        <div style={{ flex: 1 }}>
          <img alt="Logotipo do Labor" src={laborImg} />
        </div>
      </HideBeforeMedium>
      <CallToActionWrapper>
        <SocialLinks>
          <div>
            <a
              href="https://www.instagram.com/get_labor"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="Logotipo do Instagram"
                src={instagramImg}
                style={{ marginRight: "15px" }}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCLw9e0ujAxCdSJEDLgTp5rg/videos"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="Logotipo do Youtube" src={youtubeImg} />
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/getlabor/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="Logotipo do Facebook"
                src={facebookImg}
                style={{ marginRight: "15px" }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/labor-controle-de-horas"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="Logotipo do LinkedIn" src={linkedinImg} />
            </a>
          </div>
        </SocialLinks>
        <ShowBeforeMedium display="flex">
          <a
            href="https://chrome.google.com/webstore/detail/labor/ljdglolfladhpiemjpmiclhhchjfgehe"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              alt="Link para baixar extensão do Google Chrome do Labor"
              src={chromeExtensionImg}
              style={{ width: "125px" }}
            />
          </a>
        </ShowBeforeMedium>
      </CallToActionWrapper>
      <LoveTextWrapper>
        <LoveText>Feito com </LoveText>
        <img
          alt="Desenho de coração"
          src={heartImg}
          style={{
            height: "13px",
            padding: "0 5px",
            position: "relative",
            display: "inline-block",
            top: "2px",
          }}
        />{" "}
        <LoveText>pela </LoveText>
        <LoveLink
          href="https://www.novatics.com.br"
          rel="noopener noreferrer"
          target="__blank"
        >
          <strong>Novatics</strong>
        </LoveLink>
      </LoveTextWrapper>
    </BottomFooterGrid>
  )
}
