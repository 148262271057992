import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import Container from "../container"
import MenuItem, { ExternalMenuItem } from "./MenuItem"
import Link, { ExternalLink } from "../link"
import { UnstyledButton } from "../button"
import { mq, ShowBeforeLarge, HideBeforeLarge } from "../helpers"
import MobileMenu from "./MobileMenu"
import laborLogo from "./images/labor-logo.svg"
import menuMobile from "./images/menu-mobile.svg"
import loginMobile from "./images/login-mobile.svg"

const StyledHeader = styled.header`
  ${mq({
    position: "fixed",
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    height: ["70px", "82px", "91px"],
    background: "#f6f7fb",
    display: "flex",
    margin: 0,
    borderBottom: "1px solid transparent",
  })}
  ${p => p.isScrolling && `border-bottom: 1px solid #e2e5f1`}
`

const LeftNav = styled.nav`
  display: flex;
  align-items: center;
  flex: 1;
`

const RightNav = styled(LeftNav)`
  flex-direction: row;
  justify-content: flex-end;
`

export default function Header() {
  const [isScrolling, setIsScrolling] = useState(false)
  const [isMenuVisible, setMenuVisible] = useState(false)
  const [deviceId, setDeviceId] = useState(null)

  useEffect(() => {
    function handleScroll() {
      setIsScrolling(
        (window.pageYOffset ||
          (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop) > 0
      )
    }
    window.addEventListener(
      "scroll",
      typeof window !== `undefined` ? handleScroll : null
    )
    setDeviceId(
      typeof window !== 'undefined' && window.amplitude && typeof window.amplitude.getInstance().options !== 'undefined' ? 
      '?amp_device_id='.concat(window.amplitude.getInstance().options.deviceId) : 
      '?'
    )
    return () => {
      window.removeEventListener(
        "scroll",
        typeof window !== `undefined` ? handleScroll : null
      )
    }
  }, [])

  return (
    <>
      <MobileMenu
        isVisible={isMenuVisible}
        onClose={() => setMenuVisible(false)}
      />
      <StyledHeader isScrolling={isScrolling}>
        <ShowBeforeLarge display="flex" style={{ width: "100%" }}>
          <Container
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <UnstyledButton type="button" onClick={() => setMenuVisible(true)}>
              <img alt="Botão do menu" src={menuMobile} />
            </UnstyledButton>
            <Link to="/">
              <img
                alt="Logo do Labor"
                src={laborLogo}
                style={{ width: "106px" }}
              />
            </Link>
            <ExternalLink href={`${process.env.WEB_APP_URL}/entrar`}>
              <img alt="Link para Página de Login" src={loginMobile} />
            </ExternalLink>
          </Container>
        </ShowBeforeLarge>
        <HideBeforeLarge display="flex" style={{ width: "100%" }}>
          <Container style={{ display: "flex", flex: 1 }}>
            <LeftNav>
              <MenuItem to="/">Produto</MenuItem>
              {/* <MenuItem to="/planos">Planos</MenuItem> */}
              <MenuItem to="/como-usar">Como Usar</MenuItem>
              <ExternalMenuItem
                style={{ marginRight: "55px" }}
                href="https://blog.getlabor.com.br"
              >
                Blog
              </ExternalMenuItem>
            </LeftNav>
            <img alt="Logotipo do Labor" src={laborLogo} style={{ width: "133px" }} />
            <RightNav>
              <ExternalMenuItem href={`${process.env.WEB_APP_URL}/entrar`}>
                Entrar
              </ExternalMenuItem>
              {/* <ExternalMenuItem
                href={`${process.env.WEB_APP_URL}/cadastrar${deviceId}`}
                style={{ color: "#ff3c58", fontWeight: 600 }}
              >
                Comece Gratuitamente
              </ExternalMenuItem> */}
            </RightNav>
          </Container>
        </HideBeforeLarge>
      </StyledHeader>
    </>
  )
}
