import React from "react"
import styled from "@emotion/styled"
import Container from "../container"
import TopFooter from "./TopFooter"
import BottomFooter from "./BottomFooter"
import { mq } from "../helpers"

const StyledFooter = styled.footer`
  ${mq({
    borderTop: "1px solid #e2e5f1",
    display: "flex",
    flexDirection: "column",
    padding: ["25px 0", "41px 0"],
  })}
`

export default function Footer() {
  return (
    <StyledFooter>
      <Container style={{ display: "flex", flexDirection: "column" }}>
        <TopFooter />
        <BottomFooter />
      </Container>
    </StyledFooter>
  )
}
