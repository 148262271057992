import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Location } from "@reach/router"
import Link from "../link"

const StyledLink = styled(Link)`
  font-size: 16px;
  margin-right: 55px;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    font-weight: 600;
    color: #4680fe;
  }
`

function trimRightSlash(pathname) {
  if (pathname.length === 1) return pathname

  return pathname.charAt(pathname.length - 1) === "/"
    ? pathname.slice(0, -1)
    : pathname
}

export const ExternalMenuItem = StyledLink.withComponent("a")
export default function MenuItem(props) {
  const { children, to, style, ...otherProps } = props

  return (
    <Location>
      {({ location }) => {
        let isActive = false
        if (new RegExp(`${to}$`).test(trimRightSlash(location.pathname))) {
          isActive = true
        }

        return (
          <StyledLink
            style={style}
            to={to}
            className={isActive ? "active" : ""}
            {...otherProps}
          >
            {children}
          </StyledLink>
        )
      }}
    </Location>
  )
}

MenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  style: PropTypes.object,
}
