import styled from "@emotion/styled"

export default styled.button`
  display: inline-block;
  color: #ffffff;
  background: #ff3c58;
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box;
  transition: background 0.5s;
  outline: none;
  border: 0;
  padding: 10px 18px;
  cursor: pointer;
  :focus,
  :hover {
    background: #4680fe;
  }
  :disabled {
    opacity: 0.5;
  }
  text-decoration: none;
  text-align: center;
`

export const UnstyledButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

export const ButtonLikeLink = styled(UnstyledButton)`
  color: #7d7d7d;
  font-size: 13px;
  text-decoration: none;
  :hover {
    color: #4680fe;
  }
  transition: color 0.5s;
`
