import styled from '@emotion/styled';
import facepaint from 'facepaint';

export const mq = facepaint([
  // small = default = smaller than medium
  '@media(min-width: 768px)', // medium
  '@media(min-width: 1024px)', // large
  '@media(min-width: 1280px)', // extra-large
]);

export const HideBeforeMedium = styled.div`
  ${props =>
    mq({
      display: ['none', props.display ? props.display : 'block'],
    })}
`;

export const HideBeforeLarge = styled.div`
  ${props =>
    mq({
      display: ['none', 'none', props.display ? props.display : 'block'],
    })}
`;

export const HideBeforeExtraLarge = styled.div`
  ${props =>
    mq({
      display: [
        'none',
        'none',
        'none',
        props.display ? props.display : 'block',
      ],
    })}
`;

export const ShowBeforeMedium = styled.div`
  ${props =>
    mq({
      display: [props.display ? props.display : 'block', 'none'],
    })}
`;

export const ShowBeforeLarge = styled.div`
  ${props =>
    mq({
      display: [
        props.display ? props.display : 'block',
        props.display ? props.display : 'block',
        'none',
      ],
    })}
`;

export const ShowBeforeExtraLarge = styled.div`
  ${props =>
    mq({
      display: [
        props.display ? props.display : 'block',
        props.display ? props.display : 'block',
        props.display ? props.display : 'block',
        'none',
      ],
    })}
`;
